import { LabelItem } from "../../models/labelView";
import { User } from "../../models/User";
import { WorkFlowStep, WorkFlowStepTypes } from "../../models/WorkFlow";
import { WorkFlowRelativeStatus } from "../../models/WorkflowEnum";
import { WorkFlowLabRoleId } from "../../utilities/constants";
import { SearchType } from "../root/workflowOverview/createWorkflow/addSteps/singleStep/selectLabelUser/selectLabelUser.types";
import { FileSource } from "../root/workflowOverview/createWorkflow/createWorkflow.types";

type UserRequestBase = {
    pageNumber: number;
    pageSize: number;
    keyword?: string;
}

export type PreviewResponse = {
    name: string;
    mimeType: string;
    uniqueId: string;
    sharepointSite: string;
    url: string;
}

export enum GetAvailableUsersType {
    user = 0,
    label = 1
}

export type GetWorkFlowsRequest = {
    pageNumber: number; 
    pageSize: number;
    orderBy: string | undefined;
    isAscending: boolean | undefined;
    isCreator: boolean | undefined;
    workflowLabId: number | undefined;
    searchBy: string | undefined;
    status: WorkFlowRelativeStatus[] | undefined;
    creationDateFrom: string | undefined;
    creationDateTo: string | undefined;
    HideAlreadyOnEDI: boolean | undefined;
}

export type GetWorkflowsDisjointedRequest = GetWorkFlowsRequest & {
    index: number;
}

export type GetWorkFlowLabAvailableApproversRequest = UserRequestBase & {
    id: number;
    userIds: string[];
    excludeCurrentUser: boolean;
}

export type WorkflowStepShort = {
    name: string,
    type: WorkFlowStepTypes,
    order: number,
    approvers: string[],
    labelApprovers?: number[],
    typeApprovers?: GetAvailableUsersType
}

export type CreateWorkflowRequest = {
    name: string;
    description: string;
    expirationDate?: string; 
    workFlowLabId: number; 
    steps: WorkflowStepShort[]; 
    fileSource?: FileSource;
    channelId?: string;
    teamId?: string;
    teamFileId?: string;
    ediFileId?: number; 
    isDocumentEditable: boolean;
    isDocumentAttachable?: boolean;
    templateId?: number;
    LabelCategoryIds?: number[];
    disjointed?: boolean;
}

export const workflowStepToShort = (workflowStep: WorkFlowStep) : WorkflowStepShort => {
    let typeApprovers = GetAvailableUsersType.user;
    
    if( workflowStep.usersAndLabels && workflowStep.usersAndLabels[0]) {
        typeApprovers =  workflowStep.usersAndLabels[0].type === GetAvailableUsersType.label ? GetAvailableUsersType.label : GetAvailableUsersType.user;
    }
    
    return {
        name: workflowStep.name,
        order: workflowStep.order,
        type: workflowStep.type,
        approvers: ((!workflowStep.usersAndLabels || workflowStep.usersAndLabels?.length === 0) ?
            workflowStep.approvers.map(user => user.approver.id) :
            typeApprovers === GetAvailableUsersType.user ? workflowStep.usersAndLabels?.map(u => u.user.id) : []) ?? [],
        labelApprovers: (workflowStep.usersAndLabels?.length !== 0 && typeApprovers === GetAvailableUsersType.label) ? workflowStep.usersAndLabels?.map(u => u.label?.id) : [],
        typeApprovers: typeApprovers
    }
}

export type GetWorkflowsStatusRequest = {
    workflowId: number;
    getSentWorkFlows: boolean;
    dateFrom?: string;
    dateTo?: string;
}

export type GetWorkFlowsDashboardRequest = {
    workflowLabId: number | undefined;
    searchBy: string | undefined;
    dateFrom?: string;
    dateTo?: string;
}

export type getEdiFiles = {
    pageSize: number;
    pageNumber: number;
    folderId: number;
}

export type createWorkflowLabRequest = {
    name: string;
    members: User[];
}

export type GetUsersRequest =  {
    keyword?: string;
    userIds: string[];
    pageNumber: number;
    pageSize: number;
};

export type WorkflowLabMembership = {
    userId: string;
    WorkflowLabRoleId: WorkFlowLabRoleId; 
}

export type CreateWorkflowLabRequest = {
    name: string;
    members: WorkflowLabMembership[];
}

export type GetAvaiableApproversAndLabelsRequest = {
    workflowLabId: number;
    searchBy: string;
    searchType: SearchType;
    excludeCurrentUser: boolean;
    excludeUsers: string[];
    excludeLabels: number[];
}

export type UserItem = {
    email: string;
    id: string;
    firstName: string;
    lastName: string;
}

export type LabelShort = {
    id: number;
    name: string;
}

export type AvailableApproversAndLabels = {
    type: GetAvailableUsersType;
    user: UserItem;
    label: LabelItem;
}

export type ExportDisjointWorkflow = {
    name: string;
    steps: WorkflowStepShort[]; 
    label?: number[];
    labelCategoryIds? : number[];
}

export type EdiFolderResponse = {
    fileId: number,
    folderId: number,
    teamsUrl: string,
}