/*eslint-disable sonarjs/cognitive-complexity */
import { ActionButton, classNamesFunction, DefaultButton, DetailsHeader, DetailsList, DetailsRow, IColumn, IconButton, IIconProps, Label, Persona, PersonaSize, PrimaryButton, ScrollablePane, SelectionMode, Spinner, SpinnerSize, Stack, StackItem, TooltipHost } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { initReactI18next, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Accordion from "../../../../common/components/accordion/accordion";
import { AddMembersPage } from "../../../../common/components/addMembers/addMembersPage";
import EdiModal from "../../../../common/components/ediModal/ediModal";
import { PreviewSource } from "../../../../common/components/filePreview/filePreviewSource";
import MemberList from "../../../../common/components/memberList/memberList";
import { IUser } from "../../../../common/interfaces/IUser";
import { IExtPersonaProps } from "../../../../models/User";
import { WorkFlow, WorkFlowStep, WorkFlowStepStatus } from "../../../../models/WorkFlow";
import { WorkFlowDetails, WorkFlowStepDetails, WorkFlowStepDocumentDetails } from "../../../../models/WorkFlowDetails";
import { WorkFlowRelativeStatus, WorkFlowStatus, WorkflowViewType } from "../../../../models/WorkflowEnum";
import currentUser from "../../../../modules/authentication/currentUser";
import { WorkFlowLabRoleId } from "../../../../utilities/constants";
import { Helpers } from "../../../../utilities/helpers";
import { useAsyncApi } from "../../../../utilities/hooks";
import { mapper } from "../../../../utilities/mapper";
import { useCurrentWorkflowLab } from "../../../hooks/useCurrentWorkflowLab";
import { useNavigator } from "../../../hooks/useNavigator";
import { workflowApi } from "../../../services/workflow.api";
import { workFlowStepApi } from "../../../services/workflowstep/workflowstep.api";
import { mapToPersonaDetails, mapWorkflowToShort } from "../../../utilities/workflowUtilities";
import { useWorkflowState } from "../../../workflowStore";
import CopyFileOnEdiModal from "../../workflowOverview/copyOnEdiModal/CopyFileOnEdiModal";
import EdiLinkModal from "./ediLinkModal/ediLinkModal";
import StepStatus from "./stepStatus/stepStatus";
import WorkflowApprovalStep from "./workflowApprovalStep/workflowApprovalStep";
import { IWorkflowDetailsProps, IWorkflowDetailsPropsStyles, IWorkflowDetailsStyles } from "./workflowDetails.types";
import WorkflowStepHistory from "./workflowStepHistory/workflowStepHistory";
import { useWorkflowList } from "../../../features/workflowList";
import i18n from 'i18next';

const getClassNames = classNamesFunction<IWorkflowDetailsPropsStyles, IWorkflowDetailsStyles>();

export type modalType = {
    open: boolean,
    step?: WorkFlowStepDetails<WorkFlowStep>,
    loading: boolean
}

const initialStateModal = {
    step: undefined,
    open: false,
    loading: false
}

export const WorkflowDetailsBase = (props: IWorkflowDetailsProps) => {
    const classNames = getClassNames(props.styles, { theme: props.theme, className: props.className });
    const { t } = useTranslation([props.translationName, 'stepStatus', 'ediLinkModal', 'common']);
    const [workFlow, setWorkFlow] = useState<WorkFlowDetails<WorkFlow>>();
    const [workFlowStep, setWorkFlowStep] = useState<WorkFlowStep>();
    const [isWFAssignedToMe, setIsWFAssignedToMe] = useState(false);
    const [editApproversModal, setEditApproversModal] = useState<modalType>(initialStateModal);
    const [editNewApprovers, setEditNewApprovers] = useState<IUser[]>([]);
    const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);
    const selectedWorkflowLab = useCurrentWorkflowLab();
    const displayDocument = props.workFlow?.documentName !== undefined && props.workFlow?.documentName !== null ? true : false;
    const isSourceDocument = props.isSourceDocument === undefined ? true: props.isSourceDocument;
    const isWorkflowApproved = props.workFlow?.status === WorkFlowStatus.Approved;
    const cursorName : string = isSourceDocument ? 'pointer' : '';
    const visibleDocumentAttachable : boolean = props.workFlow?.isDocumentAttachable ?? false;
    const [showEdiLinkModal, setShowEdiLinkModal] = useState(false);
    const showEdiLinkButton = props.workFlow?.ediFileId !== undefined && props.workFlow?.ediFileId !== null ? true : false;
    const [copyOnEdiModalShown, setCopyOnEdiModalShown] = useState(false);
    const workflowList = useWorkflowList();
        
    const fullHistoryIcon: IIconProps = { iconName: 'FullHistory', style: { height: 'auto'} };
    const alarmClockIcon: IIconProps = { iconName: 'AlarmClock', style: { height: 'auto' } };
    const folderIcon: IIconProps = { iconName: 'Folder', style: { height: 'auto' } };

    const location = useLocation();
    const navigator = useNavigator();
    const currentWorkflowLab = useWorkflowState();
    
    const updateApprovers = async (stepId: number, approversId: string[]) => {
        setEditApproversModal({ ...editApproversModal, loading: true });
        try {
            await workFlowStepApi.UpdateStepApprover(stepId, approversId);
        }
        finally {
            setEditApproversModal(initialStateModal);
            props.onReload();
        }
    }

    const getUsers = async (keyword: string, currentUsers: IUser[]) => {
        if (keyword.length > 2) {
            return await workflowApi.getWorkFlowLabAvailableApprovers({
                id: currentWorkflowLab.workflowList.selectedWorkflowLab || -1,
                pageNumber: 0,
                pageSize: 20,
                keyword: keyword,
                userIds: currentUsers.map(x => x.id),
                excludeCurrentUser: false
            });
        }
        return []
    }

    const getFilePreviewUrl = async (stepDocumentId?: number) => {
        if(!isSourceDocument)
            return

        if(stepDocumentId !== 0 && stepDocumentId !== undefined){
            return Helpers.getPreviewFile(stepDocumentId, PreviewSource.WorkflowStep);
        }
        else {
            if(workFlow?.workFlow.id){
                return Helpers.getPreviewFile(workFlow?.workFlow.id, PreviewSource.Workflow);
            }
            if(props.workflowTemplate?.id){
                return Helpers.getPreviewFile(props.workflowTemplate?.id, PreviewSource.Template);
            }
        }
    }

    useEffect(() => {
        if (props.workFlow) {
            setWorkFlow({
                workFlow: props.workFlow,
                stepsDetails: props.workFlow.steps.sort((a, b) => a.order > b.order ? 1 : -1).map((x, i) => {
                    return {
                        step: { ...x },
                        prevStep: (x.order !== 0) ? props.workFlow?.steps.filter(s => s.order === (x.order - 1))[0] : undefined,
                        isLastStep: x.order === ((props.workFlow?.steps.length || 1) - 1),
                        isUnusedStep: props.workFlow?.status === WorkFlowStatus.Refused && x.status === WorkFlowStepStatus.Idle
                    }
                }),
                documentsDetails: props.workFlow.steps.flatMap(x => x.documents?.map(y => {
                    return {
                        document: y.document,
                        step: x.name,
                        approver: y.workFlowApprover.firstName + ' ' + y.workFlowApprover.lastName,
                        stepDocumentId: y.id,
                    }
                }))
            });
            setWorkFlowStep(props.workFlow.steps.filter(x => x.status === WorkFlowStepStatus.ToBeApproved)[0])
            setIsWFAssignedToMe(props.workFlow.relativeStatus === WorkFlowRelativeStatus.ToMe);
            
            if(location.pathname.includes("/filePreview"))
                Helpers.getPreviewFile(props.workFlow.id, PreviewSource.Workflow);
        }
    }, [props.workFlow, location.pathname]);

    const emptyList = useCallback(() => {
        if (workFlow?.stepsDetails?.length !== 0)
            return null;

        return (
            <div className={classNames.emptyList}>
                <span>{t("common:emptyList")}</span>
            </div>
        )
    }, [workFlow?.stepsDetails?.length, t, classNames.emptyList]);

    const getHistorySection = (item: WorkFlowStepDetails<WorkFlowStep>) => {
        return item.step.status !== WorkFlowStepStatus.Idle && item.step.approvers.map(x => {
            const content = `${x.approver.firstName} ${x.approver.lastName} ${t('stepStatus:userAction')} ${t(`stepStatus:approverStatus.${x.status}`)} ${t('iter.userActionWithDate')} ${x.updatedOn ? Helpers.getShortDate(x.updatedOn) : ''}`
            return <>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    <span key={x.approver.id}>
                        <TooltipHost content={content}>
                            <div className={classNames.tooltipContent} >
                                {content}
                            </div>
                        </TooltipHost>
                    </span>
                </div>
                <br></br>
            </>
        })
    }

    const getCommentsSection = (item: WorkFlowStepDetails<WorkFlowStep>) => {
        return item.step.approvers.map(x => {
            const content = `${x.approver.firstName} ${x.approver.lastName} ${t('userCommented')} "${x.comment}"`
            return x.comment && item.step.status !== WorkFlowStepStatus.Idle ?
                <>
                    <div key={x.approver.id} style={{ whiteSpace: 'pre-wrap' }}>
                        <TooltipHost content={content}>
                            <div className={classNames.tooltipContent} >
                                <span>{x.approver.firstName} {x.approver.lastName} {t('userCommented')}</span>
                                <span className={classNames.commentContent}>{`"${x.comment}"`}</span>
                            </div>
                        </TooltipHost>
                    </div>
                    <br></br>
                </> : ''
        })
    }

    const renderEditApproversButton = (item: WorkFlowStepDetails<WorkFlowStep>) => {
        return props.isOwner && !(item.step.status === WorkFlowStepStatus.Approved || item.step.status === WorkFlowStepStatus.Refused) &&
             <IconButton 
                    iconProps={{ iconName: "Edit" }}
                    onClick={() => setEditApproversModal({open: true, step: item, loading: false})} 
                /> 
    }

    const approversActionsColumns: IColumn[] = [
        {
            key: 'actionHistory',
            name: t('iter.column.actionHistory'),
            ariaLabel: 'actionHistory',
            fieldName: '',
            minWidth: 200,
            maxWidth: 230,
            isResizable: true,
            onRender: getHistorySection
        },
        {
            key: 'comments',
            name: t('iter.column.comments'),
            ariaLabel: 'comments',
            isResizable: true,
            fieldName: '',
            minWidth: 100,
            maxWidth: 130,
            onRender: getCommentsSection
        }
    ];

    const statusColumn: IColumn[] = [
        {
            key: 'status',
            name: t("iter.column.status"),
            ariaLabel: 'status',
            fieldName: '',
            minWidth: 60,
            maxWidth: 60,
            isResizable: true,
            onRender: function getValue(item: WorkFlowStepDetails<WorkFlowStep>) {
                return <span>{t(`stepStatus:status.${item.step.status}`)}</span>
            }
        }
    ];

    const rulesColumn: IColumn[] = [
        {
            key: 'rules',
            name: t('iter.column.rules'),
            ariaLabel: 'rules',
            fieldName: '',
            minWidth: 150,
            maxWidth: 180,
            isResizable: true,
            onRender: function getValue(item: WorkFlowStepDetails<WorkFlowStep>) {
                return <span style={{ whiteSpace: 'normal' }}>{t(`stepStatus:type.${item.step.type}`)}</span>
            }
        }
    ];

    const stepColumn: IColumn[] = [
        {
            key: 'step',
            name: t("iter.column.step"),
            ariaLabel: 'step',
            fieldName: '',
            minWidth: 300,
            maxWidth: 300,
            isResizable: true,
            className: classNames.step,
            onRender: function getValue(item: WorkFlowStepDetails<WorkFlowStep>) {
                return <>
                    <StepStatus isSummary={props.isSummary} item={item}></StepStatus>
                </>
            }
        }
    ];

    const approversColumn: IColumn[] = [
        {
            key: 'assignedTo',
            name: t("iter.column.approvers"),
            ariaLabel: 'approvers',
            fieldName: 'approvers',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            onRender: function getItemFrom(item: WorkFlowStepDetails<WorkFlowStep>) {
                return (
                    <div style={{ display: 'flex' }}>
                        <MemberList
                            members={mapToPersonaDetails(item.step.involvedPeople)}
                            sliceLength={2}
                            memberRounded={true}
                        />
                        {renderEditApproversButton(item)}
                    </div>);
            }
        }
    ]

    // const getDocumentSharepointPath = () => {
    //     return workFlow?.workFlow.documentUrl?.concat(
    //         Helpers.getFileExtension(workFlow.workFlow.documentUrl) === FileExtensions.Pdf ?
    //             '' :
    //             (!props.isSummary && workFlow?.workFlow.status === WorkFlowStatus.Ongoing ?
    //                 SharePointDocumentPathSuffixes.Edit :
    //                 SharePointDocumentPathSuffixes.ViewOnly));
    // }

    const columnMerger = () => {
        let completeColumn: IColumn[] = []

        if (props.isSummary)
            return stepColumn.concat(rulesColumn)

        completeColumn = completeColumn.concat(
            stepColumn
                .concat(statusColumn)
                .concat(approversColumn)
                .concat(rulesColumn)
                .concat(approversActionsColumns));

        return completeColumn;
    }

    const documentAttachedColumns: IColumn[] = [
        {
            key: 'document',
            name: t("document.column.documentName"),
            ariaLabel: 'document',
            fieldName: 'document',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            onRender: function getValue(item: (WorkFlowStepDocumentDetails | undefined)) {
                return <span style={{cursor: cursorName, textDecoration: 'underline'}} onClick={() => getFilePreviewUrl(item?.stepDocumentId)}>{item?.document}</span>
            }
        },
        {
            key: 'step',
            name: t("document.column.stepName"),
            ariaLabel: 'step',
            fieldName: 'step',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            onRender: function getValue(item: (WorkFlowStepDocumentDetails | undefined)) {
                return <span>{item?.step}</span>
            }
        },
        {
            key: 'approver',
            name: t("document.column.approverName"),
            ariaLabel: 'approver',
            fieldName: 'approver',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            onRender: function getValue(item: (WorkFlowStepDocumentDetails | undefined)) {
                return <span>{item?.approver}</span>
            }
        }
    ];

    const footerEditApprovers = (
        <React.Fragment>
            <DefaultButton
                text={t("common:cancel")}
                style={{ marginRight: "2px" }}
                onClick={() => setEditApproversModal(initialStateModal)}
                disabled={editApproversModal.loading}
            />
            <PrimaryButton
                text={t("common:save")}
                disabled={(
                    (editApproversModal.step?.step?.involvedPeople?.length ?? 0) !== editNewApprovers.length) ||
                    editApproversModal.loading
                }
                onClick={() => updateApprovers(editApproversModal.step?.step?.id ?? 0, editNewApprovers.map(e => e.id))}
            />
        </React.Fragment>
    );

    const enableRemove = (member: IExtPersonaProps) => {
        if ((editApproversModal.step?.step?.approvers.length ?? 0) === 0)
            return true;

        if (editApproversModal.step?.step?.approvers?.map(a => a?.approver?.id).includes(member?.id ?? ''))
            return false;

        return true;
    }

    const { execute: onClickSendReminder, loading: loadingReminder } =
        useAsyncApi<void, void>({
            func: async () => {
                return await workflowApi.sendWorkflowReminder(props.workFlow?.id);
            }
        });

    const openCallout = async () => {
        await onClickSendReminder();
    };

    const { execute: generateExport, loading: generatingExport } = useAsyncApi<void, void>({
        func: async () => {
            await workflowApi.exportHistoryData(props.workFlow?.id
                , 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ,i18n.use(initReactI18next).language
                , props.workFlow?.name + '.xlsx');
    }
    });

    return (
        <>
            <EdiModal
                body={
                    <AddMembersPage
                        getUsers={(keyword) => getUsers(keyword, editNewApprovers)}
                        initialState={{
                            isValid: true,
                            selectedPeople: editApproversModal.step?.step.involvedPeople ?? []
                        }}
                        onDataChange={(data) => setEditNewApprovers(data.selectedPeople)}
                        translationNamespace={"modalEditApprovers"}
                        enableSearchBar={(editApproversModal.step?.step?.involvedPeople?.length ?? 0) > editNewApprovers.length}
                        enableRemove={(member) => enableRemove(member)}
                    />
                }
                isOpen={editApproversModal.open}
                height={600}
                width={600}
                showCloseIcon
                onCloseClick={() => setEditApproversModal(initialStateModal)}
                title={t("editApprovers")}
                footer={footerEditApprovers}
            />
            {openHistoryModal ?
                <WorkflowStepHistory
                    isOpen={openHistoryModal}
                    onClose={() => setOpenHistoryModal(false)}
                    workflow={props.workFlow}
                />
            : null}
            <Stack className={classNames.root}>
                {!props.isSummary ?
                    <StackItem className={classNames.title}>
                        <div className={classNames.titleContainer}>
                            <span className={classNames.titleWrapper}>{workFlow?.workFlow.name}</span>
                            <div className={classNames.closeBtn} style={{ alignItems: 'center' }}>
                                {loadingReminder &&
                                    <div className={classNames.closeBtn}>
                                        <Spinner size={SpinnerSize.medium}
                                        />
                                    </div>
                                }
                                {(generatingExport) && <Spinner size={SpinnerSize.small} />}
                                <ActionButton
                                    disabled={generatingExport}
                                    onClick={() => generateExport()}
                                    text={t('exportReport')}
                                />
                                <IconButton
                                    style={{marginRight: 5}}
                                    iconProps={fullHistoryIcon}
                                    disabled={loadingReminder}
                                    onClick={() => setOpenHistoryModal(true)}
                                />
                                {selectedWorkflowLab?.roleId !== WorkFlowLabRoleId.User &&
                                selectedWorkflowLab?.roleId !== WorkFlowLabRoleId.Auditor &&
                                props.workFlow?.status !== WorkFlowStatus.Refused &&
                                props.workFlow?.status !== WorkFlowStatus.Approved &&
                                    <PrimaryButton
                                        style={{ margin: 'inherit' }}
                                        onClick={openCallout}
                                        disabled={loadingReminder}
                                        iconProps={alarmClockIcon}
                                        text={t('sendReminder')}
                                    />
                                }
                                {showEdiLinkButton &&
                                    <PrimaryButton
                                        style={{ margin: 'inherit' }}
                                        text={t('ediLinkModal:button')}
                                        iconProps={folderIcon}
                                        onClick={() => setShowEdiLinkModal(true)}
                                    />
                                }
                                <DefaultButton
                                    text={t('common:close')}
                                    onClick={() => navigator.changeWorkflowLab(currentWorkflowLab.workflowList.selectedWorkflowLab)}
                                />
                            </div>
                        </div>
                        <div className={classNames.description}>
                            <TooltipHost content={workFlow?.workFlow.description}>
                            <div className={classNames.tooltipContent} >
                                {workFlow?.workFlow.description}
                            </div>
                        </TooltipHost>
                        </div>
                    </StackItem> : ''
                }
                <StackItem><span>{props.workFlow && props.workFlow.relativeStatus !== undefined ? t(`info.status.${props.workFlow?.relativeStatus}`) : ''}</span></StackItem>
                <StackItem><span>{props.workFlow && props.workFlow.relativeStatus !== undefined ? t(`info.secondLineStatus.${props.workFlow?.relativeStatus}`) : ''}</span></StackItem>
                <StackItem className={`${classNames.mainContent} ${props.isSummary ? '' : classNames.extended}` }>
                    <ScrollablePane>
                        <Accordion styles={classNames.subComponentStyles.accordion} title={t('info.title')} opened={true} stickyHeader>
                            <div className={classNames.accordionContent}>
                                <Stack tokens={{ childrenGap: 10 }}>
                                    {props.isSummary &&
                                        <Stack tokens={{ childrenGap: 30 }}>
                                            <Stack tokens={{ childrenGap: 10 }}>
                                                <StackItem><span style={{ fontWeight: 'bold' }}>{t('info.workflowname')}</span></StackItem>
                                                <StackItem style={{ marginTop: 5 }}><span>{workFlow?.workFlow.name}</span></StackItem>
                                            </Stack>
                                        </Stack>
                                    }
                                    <Stack horizontal tokens={{ childrenGap: 30 }}>
                                        {displayDocument && 
                                            <Stack tokens={{ childrenGap: 10 }} style={{maxWidth: '50%', minWidth: '20%'}}>
                                                <StackItem style={{ display: 'flex', alignItems: 'center'}}>
                                                    <StackItem>
                                                        <span style={{ fontWeight: 'bold' }}>{t('info.document')}</span>
                                                    </StackItem>
                                                    {workflowList.workflowLabSelectedChoiceGroup !== WorkflowViewType.received && 
                                                    props.workFlow?.relativeStatus === WorkFlowRelativeStatus.Approved &&
                                                    (props.workFlow?.creator?.id === currentUser.currentUserId || selectedWorkflowLab?.roleId === WorkFlowLabRoleId.Owner) && 
                                                    !props.workFlow?.ediFileId &&
                                                    selectedWorkflowLab?.roleId !== WorkFlowLabRoleId.Auditor &&
                                                        <StackItem>
                                                                <IconButton
                                                                    iconProps={{ iconName: "Share" }}
                                                                    text="Save to EDI"
                                                                    onClick={(e) => {
                                                                        setCopyOnEdiModalShown(true);
                                                                    }}
                                                                />
                                                        </StackItem>
                                                    }
                                                </StackItem>
                                                <StackItem style={{ marginTop: 5 }}>
                                                    <TooltipHost content={workFlow?.workFlow.documentName}>
                                                        <div className={props.isSummary ? '' : classNames.tooltipContent} >
                                                            <span 
                                                                className={classNames.documentUrl}
                                                                onClick={() => getFilePreviewUrl()}>
                                                                {workFlow?.workFlow.documentName}
                                                            </span>
                                                        </div>
                                                    </TooltipHost>
                                                </StackItem>
                                                <StackItem style={{ marginTop: 0 }}>
                                                    <Label style={{ fontSize: '12px' }}>{workFlow?.workFlow.isDocumentEditable ? t('documentEditable') : t('documentNotEditable')}</Label>
                                                </StackItem>
                                            </Stack>
                                        }
                                        {!props.isSummary &&
                                            <Stack tokens={{ childrenGap: 5 }}>
                                                <StackItem>
                                                    <span style={{ fontWeight: 'bold' }}>{t('info.createdBy')}</span>
                                                </StackItem>
                                                <StackItem>
                                                    <Persona {...mapper.mapUserToPersona(workFlow?.workFlow.creator)} size={PersonaSize.size24} coinSize={32} />
                                                </StackItem>
                                            </Stack>
                                        }
                                    </Stack>
                                </Stack>
                            </div>
                        </Accordion>
                        
                        {!props.isSummary && workFlowStep && isWFAssignedToMe &&
                            <Accordion styles={classNames.subComponentStyles.accordion} title={t('action.title')} opened={true} stickyHeader >
                                <div className={classNames.accordionContent}>
                                    <WorkflowApprovalStep 
                                        onReload={() => props.onReload()}
                                        workflowStep={workFlowStep}
                                        steps={workFlow?.stepsDetails || []}
                                        isDocumentAttachable={workFlow?.workFlow.isDocumentAttachable ?? false}
                                        >
                                    </WorkflowApprovalStep>
                                </div>
                            </Accordion>
                        }
                        {visibleDocumentAttachable &&
                        <Accordion styles={classNames.subComponentStyles.accordion} title={t('document.title')} opened={!isWFAssignedToMe} stickyHeader 
                            customHeader={(isWorkflowApproved &&
                                workFlow?.workFlow.isDocumentAttachable?.valueOf() && 
                                workFlow?.documentsDetails?.length !== 0 &&
                                workFlow?.workFlow.creator?.id === currentUser.currentUserId &&
                                selectedWorkflowLab?.roleId !== WorkFlowLabRoleId.User &&
                                selectedWorkflowLab?.roleId !== WorkFlowLabRoleId.Auditor) ?
                                <IconButton
                                    iconProps={{iconName:"Share"}}
                                    onClick={()=>{setCopyOnEdiModalShown(true)}}
                                /> :
                                <p/>
                            }>
                            <div className={classNames.accordionContent}>
                                <DetailsList
                                    items={workFlow?.documentsDetails || []}
                                    columns={documentAttachedColumns}
                                    selectionMode={SelectionMode.none}
                                    isHeaderVisible={true}
                                    onRenderDetailsHeader={props => props ? <DetailsHeader {...props} styles={classNames.subComponentStyles.detailsHeader} /> : null}
                                    setKey="none"
                                    styles={classNames.subComponentStyles.detailsList}
                                    onRenderRow={props => props ? <DetailsRow id={props.id} {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
                                    onRenderDetailsFooter={emptyList}
                                />                                
                            </div>
                        </Accordion>}
                        <Accordion styles={classNames.subComponentStyles.accordion} title={t('iter.title')} opened={!isWFAssignedToMe} stickyHeader>
                            <div className={classNames.accordionContent}>
                                <DetailsList
                                    items={workFlow?.stepsDetails || []}
                                    columns={columnMerger()}
                                    selectionMode={SelectionMode.none}
                                    isHeaderVisible={true}
                                    onRenderDetailsHeader={props => props ? <DetailsHeader {...props} styles={classNames.subComponentStyles.detailsHeader} /> : null}
                                    getKey={((item: WorkFlowStepDetails<WorkFlowStep>) => item.step.id.toString())}
                                    setKey="none"
                                    styles={classNames.subComponentStyles.detailsList}
                                    onRenderRow={props => props ? <DetailsRow id={props.id} {...props} styles={classNames.subComponentStyles.detailsRow} /> : null}
                                    onRenderDetailsFooter={emptyList}
                                />
                            </div>
                        </Accordion>
                    </ScrollablePane>

                    {showEdiLinkModal &&
                        <EdiLinkModal 
                            onClose={() => setShowEdiLinkModal(false)}
                            isOpen={showEdiLinkModal}
                            workflowId={props.workFlow?.id ?? 0}
                        />
                    }
                </StackItem>
            </Stack>
            {copyOnEdiModalShown &&
                <CopyFileOnEdiModal
                    initialState={{
                        folder: 0
                    }} 
                    selectedWorkfows={workFlow ? mapWorkflowToShort([workFlow.workFlow]) : []}
                    selectedDocuments={workFlow ? workFlow?.documentsDetails : []}
                    reloadList={()=> console.log('')} 
                    saveModalShown={copyOnEdiModalShown}
                    closeModal={()=>{setCopyOnEdiModalShown(false);}}
                />
            }
        </>
    );
}