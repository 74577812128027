import { CurrentUserInfo } from "./user.contracts";
import { usersApi } from "./users.api";

class CurrentUser {
    private currentUserProfile?: CurrentUserInfo;
    public currentUserId?: string;

   constructor() {
        this.currentUserProfile = undefined;
    }

    public get getUserRoles(){
        return this.currentUserProfile?.roles
    }

    public get getUserInfo(){
        return this.currentUserProfile;
    }

    async getUserProfile(currentUserId: string) {
        const response = await usersApi.getCurrentUserInfo(currentUserId);
        this.currentUserProfile = response;
        this.currentUserId = currentUserId;
    }
}

// Assegna l'istanza a una variabile
const currentUserInstance = new CurrentUser();

// Esporta la variabile come default
export default currentUserInstance;