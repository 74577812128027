import { AxiosRequestConfig } from "axios";
import QueryString from "qs";
import { WorkFlowDisjointedInformations } from "../../../models/WorkflowShort";
import { apiClient } from "../../../modules/apiClient/apiClient";
import { svcPaths } from "../../../modules/apiClient/config";
import { inputFileMetaData } from "../../root/workflowOverview/copyOnEdiModal/CopyFileOnEdiModal.base";
import { CreateWorkflowRequest, ExportDisjointWorkflow, GetWorkflowsDisjointedRequest } from "../workflow.contracts";
import { GetWorkFlowsDashboardDisjointRequest, WorkFlowsDashboardDisjointResponse } from "./workflowDisjointed.contracts";

class WorkflowDisjointedApiClass {
    async createWorkflowDisjointed(request: CreateWorkflowRequest, file: File){
        const formdata: FormData = new FormData();
        formdata.append('File', file);
        formdata.append('jsonCommand', JSON.stringify(request));

        const response = await apiClient.post(`${svcPaths.workflow}/workflowDisjointed/create`, formdata);
        return response.data; 
    }

    async sendWorkflowDisjointedeminder(workflowId: number | undefined)
    {
        const payload = {workflowDisjointedId: workflowId}
        const response = await apiClient.post(`${svcPaths.workflow}/workflowDisjointed/sendReminder`, payload);
        return response.data
    }

    async getWorkflowsDisjointed(request: GetWorkflowsDisjointedRequest){
        const response = await apiClient.get<WorkFlowDisjointedInformations>(`${svcPaths.workflow}/workflowDisjointed`, {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                orderBy: request.orderBy,
                isAscending: request.isAscending,
                workFlowLabId: request.workflowLabId,
                isCreator: request.isCreator,
                searchBy: request.searchBy,
                status: request.status,
                creationDateFrom: request.creationDateFrom,
                creationDateTo: request.creationDateTo,
                index: request.index,
                HideAlreadyOnEDI: request.HideAlreadyOnEDI
            },
            paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
        })
        return response.data;
    }

    async exportDisjointedWorkflowInfo(disjointedId: number, type: string, filename: string, onDownloadProgress?: (progress: number) => void) {
        const config: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        }
        const response = await apiClient.get(`${svcPaths.workflow}/workflowDisjointed/${disjointedId}/exportDisjointed`, config);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: type }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }

    // async exportDisjointedWorkflowInfo(disjointedId: number) {
    //     const response = await apiClient.get(`${svcPaths.workflow}/workflowDisjointed/${disjointedId}/exportDisjointed`);
    //     return response.data;
    // }

    async exportDisjointWorkflow(workflowLabId: number, type: string, filename: string, request: ExportDisjointWorkflow) {
        const config: AxiosRequestConfig = {
            responseType: 'arraybuffer',
        }
        const response = await apiClient.post(`${svcPaths.workflow}/workflowDisjointed/${workflowLabId}/exportPreviewDisjointedWorkflow`, request, config);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: type }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }

    async getWarning(workflowLabId: number, request: ExportDisjointWorkflow){
        const response = await apiClient.post(`${svcPaths.workflow}/workflowDisjointed/${workflowLabId}/getWarningPreviewDisjointedWorkflow`, request);
        return response.data;
    }

    async getWorkflowDashboardDisjointed(request: GetWorkFlowsDashboardDisjointRequest) {
        const response = await apiClient.get<WorkFlowsDashboardDisjointResponse[]>(`${svcPaths.workflow}/workflowDisjointed/GetWorkFlowDisjointedDashBoard`, {
            params: {
                workFlowLabId: request.workflowLabId,
                userId: request.userId,
                expirationDateFrom: request.dateFrom,
                expirationDateTo: request.dateTo,
            }
        });
        return response.data;
    }

    async uploadFilesToEdi(Inputs: inputFileMetaData[], folderId:number) {
        const payload = {WorkflowInputs:Inputs, ediFolderId: folderId};        
        const response = await apiClient.post(`${svcPaths.workflow}/workflowDisjointed/uploadToEdi`, payload);
        return response.data;
    }

    async deleteWorkflowDisjointed(Id: number){
        const response = await apiClient.delete(`${svcPaths.workflow}/workflowDisjointed/${Id}`);
        return response.data;
    }

}

export const workflowDisjointedApi = new WorkflowDisjointedApiClass();